import React from "react";

import { makeStyles } from "tss-react/mui";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import Button from "../CustomButtons/Button.js";

import styles from "../../styles/jss/nextjs-material-kit/components/headerLinksStyle.js";
import { useRouter } from "next/router";
import Link from "next/link";

const useStyles = makeStyles()(styles);

const navLinks = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Apprenticeships",
    link: "/jobs",
    link2: "/job/[slug]/[jobId]",
  },
  {
    name: "Career zones",
    link: "/career-zones",
    link2: "/career-zone/[slug]/[careerzoneId]",
  },
  {
    name: "Advice",
    link: "/advice",
    link2: "/advice/[slug]/[articleId]",
  },
  {
    name: "Employers",
    link: "/employers",
    link2: "/employer/[slug]/[employerId]",
  },
  // {
  //   name: "Universities",
  //   link: "/universities",
  //   link2: "/university/[slug]/[universityId]",
  // },
  {
    name: "News",
    link: "/news",
    link2: "/news/[slug]/[articleId]",
  },
  {
    name: "Communities",
    link: "/communities",
  },
];

export default function HeaderLinks(props) {
  const { classes, cx } = useStyles();
  const router = useRouter();

  const activeLink = router.pathname;

  return (
    <List className={cx(classes.list, classes.menuRightOffset)}>
      {navLinks &&
        navLinks.map((navItem, index) => {
          let isActive =
            navItem.link === activeLink ||
            navItem?.link2 === activeLink ||
            navItem?.link3 === activeLink;
          return (
            <ListItem key={index} className={cx(classes.listItem)}>
              <Link href={navItem.link}>
                <Button
                  color="transparent"
                  className={cx(
                    classes.navLink,
                    isActive ? "navLinkActive" : null
                  )}
                >
                  {navItem.name}
                </Button>
              </Link>
              {isActive && <div className={"active"}></div>}
            </ListItem>
          );
        })}
    </List>
  );
}
